<template>
  <div>
    <ConnectWallet />
    <div class="z-0" v-if="getToken == 'false'">
      <!-- <div
        class="bg-cover h-screen w-screen bg-center flex items-center justify-center"
        style="
          background-image: url('https://cdn.discordapp.com/attachments/1080058777971212370/1121284478967496785/news-concept-with-newspapers-2021-08-26-15-42-03-utc.jpg');
        "
      >
        <div class="text-center">
          <h2
            class="font-sans text-3xl font-bold sm:text-7xl md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-gray-300 to-gray-100 animate-charcter"
          >
            VIRTUAL NEWS
          </h2>
        </div>
      </div> -->

      <News />

      <StickFooter />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import News from "@/components/News.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    ConnectWallet,
    News,
    StickFooter,
  },
  computed: mapGetters(["getToken"]),

  methods: {},
  created() {},
};
</script>
